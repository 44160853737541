.footer {
    background-color: #fafafa;
    font-size: 14px;
    color: #777777;
    height: 180px;
    position: sticky;
    bottom: 0;
    z-index: 2;
}

.footer-nav {
    list-style: none;
    padding: 0px;
}

.footer-nav li {
    display: inline;
}

.footer-link, .footer-link:hover {
    color: #2CB8DF;
    text-decoration: none;
}