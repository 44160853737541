.vh-fill {
    min-height: calc(100vh - 180px);
}

.vh-70 {
    min-height: 70vh;
}

.header {
    background-image: url('/public/images/faq_Header.jpg');
}

.header-box {
    background-color: #fff;
}

.powur-btn-primary {
    background-color: #20C2F1;
    border: #20C2F1;
}

.powur-btn-primary:hover {
    background-color: #2CB8DF;
    border: #2CB8DF;
}

.powur-btn-secondary {
    background-color: #ffffff;
    border: 1px solid #20C2F1;
    color: #20C2F1;
}

.powur-btn-secondary:hover {
    background-color: #2CB8DF;
    border: 1px solid #2CB8DF;
}

.powur-link {
    color: #20C2F1 !important;
    text-decoration: none !important;
}

.bg-powur-blue {
    background-color: #20C2F1;
}

.accordion-button::after {
    text-align: center;
    margin: auto;
}
.alertmessage{
    height:40px;
}
.ErrorMessageBlackText{
    color:black;
}